import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  CARD_SIZE_SMALL,
  CARD_SIZE_MEDIUM,
} from '@wix/communities-forum-client-commons/dist/src/constants/card-sizes';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import Masonry from '../../../common/components/masonry';
import { SortingSelectCategoryPageDesktop } from '../sorting-select-category';
import CreatePostButton from '../create-post-button/create-post-button';
import { getCardSize } from '../../selectors/app-settings-selectors';
import { connect } from '../../../common/components/runtime-context';
import NoPostsFiltered from '../no-posts-filtered';
import MarkAllAsReadButton from '../mark-all-as-read-button';
import { CategorySelectHeadless } from '../category-select';
import LoadMore from '../load-more';
import { POSTS_PER_PAGE } from '../../constants/pagination';
import PostListItemVirtualized from '../post-list-item-virtualized';
import styles from './post-list-masonry-responsive.scss';

const TYPES = {
  [CARD_SIZE_SMALL]: 'small',
  [CARD_SIZE_MEDIUM]: 'medium',
};

const getMasonryBreakpointsByCardSize = cardSize => {
  if (cardSize === CARD_SIZE_SMALL) {
    return {
      default: 3,
      750: 2,
      600: 1,
    };
  } else if (cardSize === CARD_SIZE_MEDIUM) {
    return {
      default: 2,
      600: 1,
    };
  }
};

const renderCreateNewPostButton = (categorySlug, postTypes, createPostCtaLabel) => {
  return (
    <CreatePostButton
      categorySlug={categorySlug}
      postTypes={postTypes}
      createPostCtaLabel={createPostCtaLabel}
    />
  );
};

const renderSortingSelect = () => (
  <div className={styles.selectContainer}>
    <SortingSelectCategoryPageDesktop />
  </div>
);

const renderCategorySelect = () => {
  return (
    <div className={styles.selectContainer}>
      <CategorySelectHeadless />
    </div>
  );
};

const renderLoader = () => {
  return (
    <div className={styles.loader}>
      <Loader />
    </div>
  );
};

const PostListMasonryResponsive = ({
  category,
  posts,
  onLikeClick,
  showCreatePostAction,
  showCategoryLink,
  cardSize,
  uniquePostTypesInAllCategories,
  hasActiveFilter,
  showCategoryFilter,
  loadMore,
  showLoaderInLoadMore,
  entityCount,
  isLoading,
  customCtaLabel,
}) => {
  const className = classNames(styles.masonry);
  const isOnSecondOrMorePage = Math.ceil(posts.length / POSTS_PER_PAGE) >= 2;

  const renderControls = () => {
    const hasOnlyOnePostTypeCategory = uniquePostTypesInAllCategories.length === 1;
    const postTypes = hasOnlyOnePostTypeCategory
      ? uniquePostTypesInAllCategories
      : category.postTypes;

    return (
      <div className={classNames(styles.controls)} data-hook="post-list-masonry-controls">
        <div className={styles.controlsSide}>
          {showCategoryFilter && renderCategorySelect()}
          {renderSortingSelect()}
        </div>
        <div className={styles.controlsSide}>
          <MarkAllAsReadButton className={styles.markAllAsRead} category={category} />
          {showCreatePostAction &&
            renderCreateNewPostButton(category.slug, postTypes, customCtaLabel)}
        </div>
      </div>
    );
  };
  return (
    <div>
      {renderControls()}
      <LoadMore
        loadMore={loadMore}
        isLoading={showLoaderInLoadMore}
        showButton={showLoaderInLoadMore || isOnSecondOrMorePage}
        remainingEntities={entityCount - posts.length}
      >
        {!isLoading && !posts.length && hasActiveFilter ? (
          <NoPostsFiltered />
        ) : (
          <Masonry
            columnClassName={styles.masonryColumn}
            containerWidth={960}
            breakpointCols={getMasonryBreakpointsByCardSize(cardSize)}
            className={className}
            data-hook="post-list"
          >
            {posts.map(post => (
              <PostListItemVirtualized
                key={post._id}
                post={post}
                category={category}
                onLikeClick={onLikeClick}
                showCategoryLink={showCategoryLink}
                type={TYPES[cardSize]}
              />
            ))}
          </Masonry>
        )}
        {isLoading && renderLoader()}
      </LoadMore>
    </div>
  );
};

PostListMasonryResponsive.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  query: PropTypes.string,
  showCreatePostAction: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
  cardSize: PropTypes.number,
  hasActiveFilter: PropTypes.bool,
  uniquePostTypesInAllCategories: PropTypes.array,
  showCategoryFilter: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  showLoaderInLoadMore: PropTypes.bool,
  entityCount: PropTypes.number,
  isLoading: PropTypes.bool,
  showMemberPosts: PropTypes.bool,
  customCtaLabel: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  cardSize: getCardSize(state, host.style),
});

export default flowRight(connect(mapRuntimeToProps))(PostListMasonryResponsive);
